// 查看pdf
<template>
    <el-dialog
        :close-on-click-modal="false"
        :modal-append-to-body="true"
        :append-to-body="true"
        :visible="true"
        width="800px"
        :before-close="close"
    >
        <div slot="title">
            <el-tooltip :content="title">
                <span
                    style="line-height: 24px;font-size: 18px;color: #ffffff;"
                    >{{ titleMaxLen }}</span
                >
            </el-tooltip>
        </div>
        <el-row
            v-loading="loadingPdf"
            :element-loading-text="
                loadedRatio === 1 ? '下载完成，正在解码' : '正在下载，请稍后'
            "
        >
            <div class="pdfContainer">
                <pdf
                    ref="pdf"
                    :src="pdfUrl"
                    @progress="loadedRatio = $event"
                    :page="page"
                >
                </pdf>
            </div>
            <div class="mt20">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="numPages"
                    :pageSize="1"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </div>
        </el-row>
    </el-dialog>
</template>
<script>
import pdf from "vue-pdf";
export default {
    name: "viewPdf",
    components: {
        pdf,
    },
    props: {
        title: {
            type: String,
            default: "预览pdf",
        },
        /* 预览pdf地址 */
        pdfUrl: {},
    },
    watch: {},
    data() {
        return {
            numPages: 0, //pdf总页数
            page: 1, // 当前页数
            loadedRatio: 0, //pdf进度
            loadedText: 0, //pdf进度加载提示
            loadingPdf: true, //pdf进度加载
        };
    },
    mounted() {
        this.getNumPages();
    },
    methods: {
        // 关闭弹窗
        close() {
            this.$emit("close");
        },

        //获取pdf总页数
        getNumPages() {
            this.loadingPdf = true;
            let loadingTask = pdf.createLoadingTask(this.pdfUrl);
            loadingTask.promise
                .then((pdf) => {
                    this.numPages = pdf.numPages;
                })
                .catch(() => {
                    this.$message({
                        message: "PDF文件加载失败,请重新尝试！",
                        type: "error",
                    });
                })
                .finally(() => {
                    setTimeout(() => {
                        this.loadingPdf = false;
                    }, 500);
                });
        },
        // 分页改变
        handleCurrentChange(val) {
            this.page = val;
        },
    },
    computed: {
        titleMaxLen() {
            let title = this.title;
            if (this.title.length > 50) {
                title = `${this.title.slice(0, 50)}...`;
            }
            return title;
        },
    },
};
</script>
<style scoped>
.pdfContainer {
    height: 600px;
    overflow: auto;
}
</style>
